<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
    <div class='transition' :style='getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 12pt; font-weight: bold;width: 100%;'>Import Bookings (TVLOAD) 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 9pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		       
		        <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			
			    <GSelectSimple :options="stations" style='width:100%' v-model="stationId" @changed='stationChanged'/>
			    
			    <div v-if="data && data.media">
			         <center>
			    		<GImage :data="getImgData( data.media.logo)"/>
			         </center>
			    </div>
				<div v-if="allAdvertiser && allAdvertiser.length">
					<span class='SVcontrolLabel'>Advertiser (Filter):</span><br/>
					<GSelectSimple :options="allAdvertiser" style='width:100%' v-model="advertiser" @changed='advChanged'/>
				</div>
			    <span class='SVcontrolLabel'>Date:</span><br/><InputDatePick  @change="dateChanged" :auto="false" v-model="date"/>
			    <br/>
				<div v-if="data && data.media">
					<button type="button" style="button" @click="syncMaterial(data.spots)">SYNC MATERIAL</button>
				</div>	
			</div>   
	</div>
	
    <div class='transition uploadArea' style='float: top; position: relative;' id='uploader' 
		 		@drop="dropFile($event, 0, 0, 'uploader');"
			    @dragenter.prevent="hoverLine( 'uploader')"
			    @dragleave.prevent="leaveLine( 'uploader')"		        
				@dragover.prevent="hoverLineMain( 'uploader')" >
				<div v-if="data && data.media">
					Channel: {{data.media.name}} <br/>
					Date: {{data.date}} <br/>
					Spots: {{data.nSpots}} <br/>
					<div v-if="data.spots.length">
						Imported: {{data.spots.length}} of {{data.nSpots}} <br/>
						<button type="button" style="button" @click="showBreaks(data.spots)">show breaks</button>
					</div>	
					<div v-else>
						Already imported: {{data.existing.length}} <br/>
						Size: {{Math.round(data.data.length/1024)}}kB<br/>
									
						<button type="button" style="button" @click="importBookings">Import this file</button>	
						<button v-if="data.existing.length" type="button" style="button" @click="showBreaks(data.existing)">show breaks</button>			
					</div>	
					<div v-if="times" style="height: calc(70vH); overflow-y: auto;">
						<div v-for="t in times" :key="'t'+t">
							<div class="breakLine" v-if="spots.filter(p=>p.starttime===t&&p.clientId===advertiser).length" @click="openBreak( t)" style='font-size: 11pt; font-weight: bold; cursor: pointer;'>
								{{ printTimeHHMM( t)}} 
								{{ spots.find(p=>p.starttime===t).breakCode}}
								{{ spots.filter(p=>p.starttime===t).length}}
								{{ printTime(spots.filter(p=>p.starttime===t).map(p=>p.length).reduce((a,b)=>a+b))}}
								({{ spots.filter(p=>p.starttime===t && p.clientId===advertiser).length}})
							</div>
							<div class="breakLine" v-else @click="openBreak( t)" style='font-size: 11pt; cursor: pointer;'>
								{{ printTimeHHMM( t)}} 
								{{ spots.find(p=>p.starttime===t).breakCode}}
								{{ spots.filter(p=>p.starttime===t).length}}
								{{ printTime(spots.filter(p=>p.starttime===t).map(p=>p.length).reduce((a,b)=>a+b))}}
							</div>
							<div v-if="openedBreak === t">
								<table>
								<tr v-for="(s,si) in spotsInBreak" :key="'sp'+si">
									<td class="spotTdS">{{s.position}}</td>
									<td v-if="s.clientId===advertiser" class="spotTdL" style="font-weight: bold">{{s.advertiser}}</td>
									<td v-else class="spotTdL">{{s.advertiser}}</td>
									<td class="spotTdM">{{s.spotGroup}}</td>
									<td class="spotTdM right">{{Math.round(s.gross*100)*.01}}</td>
									<td class="spotTdM right">{{s.length}}</td>
									<td class="spotTdL">{{s.spot}}</td>
								</tr>	
								</table>
							</div>	
						</div>	
					</div>
				</div>
				<div v-else style='padding-top: 50pt; display: block; text-align: center; vertical-align: middle; align-items: center; justify-content: center; height: 100%; width: 100; border: 2pt solid #fcd;'>
			      <span style='color: #aaa;'>drop booking file</span>
			    </div>
		 
 	</div>
 	
	<ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem v-if="contextData.header" @clicked="$refs.menu.close(); $refs.editor.open('GridHeader', contextData.header.id)" >
          Open {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.detail" @clicked="$refs.menu.close(); $refs.editor.open('GridDetail', contextData.detail.id)" >
          Open {{contextData.detail.name}}
        </ContextMenuItem>
        <ContextMenuSep v-if="!contextData.detail"/>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); activate( contextData.header)">
          Activate {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); compare( contextData.header)">
          Compare
        </ContextMenuItem>
       </template>
    </ContextMenu>
	<PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
	<GConfirm ref='confirm'/>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
 </div>
</template>	
<script>
import {HTTP, HTTPMP, gridAPI, bookingImportAPI, reportAPI, opsAPI, bngAPI, fwAPI, userAPI, invAPI, setReload, showError, myLocale, formatNumber} from '@/variables.js';
import {store, restore} from '@/gridImport.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import { setGoBack } from '@/breadCrumb.js';
import {printTimeOpt, printTimeHHMM, printTime } from '@/basicTimeFN.js';
import ProgressBar from '@/components/ProgressBar';
import Switch from '@/components/Switch';
import GImage from '@/components/GImage';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuItem from '@/components/ContextMenuItem';
import RunDownAsRun from '@/components/asrun/RunDownAsRun';
import InputWeekdays from '@/components/inputElements/DisplayWeekdays';
import RunDown from '@/components/asrun/RunDown';
import GSelectMSimple from '@/components/GSelectMSimple';
import GSelectSimple from '@/components/GSelectSimple';
import GSelect from '@/components/misc/GSelect';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import InputDatePickM from '@/components/inputElements/InputDatePickM'; 
import GridBreakView from '@/components/GridBreakView';
import GConfirm from '@/components/GConfirm';
import PDFViewer from '@/components/PDFViewer';
import JQuery from "jquery";
import 'w3-css/w3.css';
var momentTZ = require('moment-timezone')
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW_IMPORTBOOKINGS',
   
  components : {
   //vSelect, 'app-switch': Switch, InputDatePick, GConfirm, GSelectSimple, RunDownAsRun, RunDown
   GConfirm, GImage, ProgressBar, InputDatePick,  PDFViewer, ContextMenu, ContextMenuItem, ContextMenuSep,GSelectSimple
  },
  data () {
    return {
      stationId: 0,
      controlsRight: false,
      station: {},
      stations: [],
	  reports: [],
	  user: {},
      data: {},
	  reportData: {},
      dataStored: {},
      fileId: 0,
      headers: null,
      compareData: null,
      date: null,
      dates: [],
      emptyBreaks: 1,
      emptyBreaksActive: 1,
      selectedHeader: null,
	  gridImported: null,
	  allAdvertiser: [],
	  advertiser: 0,
      //
      times: [],
	  spots: [],
	  spotsInBreak: [],
	  openedBreak: 0,
      //
      categories: [],
      categoryIds: [],
      //
      overbook: true,
      fillLastBreak: true,
      unplaced: false,
      // ProgBar
      pbFromGridId: 0,
      pbToGridId: 0,
      pbHeaderId: 0,
      pbAction: "",
      pbData: {},
      pbTitle: "",
      showProgressBar: false,
      getBGStyle2, formatNumber,getAppStyle, printTimeHHMM
      }
  },
  methods: {
	  getControlStyle() {
	    	if ( this.controlsRight )
	    	{
	    		return "float: right; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    	}
	    	return "float: left; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    },
	    getImgData(val) { return 'data:image/png;base64,' + val; },
	    openScheduleView(stationId, isoDay)
	    {
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	this.$router.replace('scheduleView?stationId='+stationId+'&day='+ isoDay);
	    },
	    getRatecard(compareData, id)
	    {
	    	if (compareData.ratecards[id])
	    	{
	    		return compareData.ratecards[id];
	    	}
	    	return {};
	    },
		advChanged()
		{
			console.log( this.advertiser);
		},
		openBreak( time)
		{
			if ( this.openedBreak == time)
			{
				this.openedBreak = 0;
				return;
			}
			this.openedBreak = time;
			let spotsInBreak = this.spots.filter(p=>p.starttime===time);
			spotsInBreak.sort((a,b)=>a.position-b.position);
			this.spotsInBreak = spotsInBreak;
		},
	    getGridZone(compareData, d)
	    {
	    	let zone = compareData.gridZones.find(p=>p.id==d.zoneId);
	    	if ( !zone)
	    	{
	    		return {}
	    	}
	    	return zone;
	    },
	    setDates(d)
	    {
	    
	    	this.dates =d;
	    },
		showBreaks( spots)
		{
			this.spots = spots;
			let times = spots.map(p=>p.starttime);
			times.sort( (a,b) => a-b);
			this.times =  [...new Set(times)];
		
			let clientIds = spots.map(p=>p.clientId);
			clientIds =  [...new Set(clientIds)];
			let advertisers = [];

			for ( let id in clientIds)
			{
				let cId = clientIds[id];
				advertisers.push({ id: cId, label: spots.find(p=>p.clientId===cId).advertiser, visible: true});				
			}
			advertisers.sort( (a,b) => a.label.localeCompare(b.label));
			this.allAdvertiser = advertisers;
		},
	    filter( details, emptyBreaks)
	    {
	    	let d = details;
	    	if ( this.categoryIds && this.categoryIds.length)
	    	{
	    		d = d.filter( x => this.categoryIds.includes( this.compareData.categoryMap[x.id].id))	
	    	}
	    	if ( this.selectedSlots && this.selectedSlots.length )
	    	{
	    		d = d.filter( x => this.selectedSlots.find(p=>p.id==x.starttime))	
	    	}
	    	if ( this.selectedZones && this.selectedZones.length )
	    	{
	    		d = d.filter( x => this.selectedZones.find(p=>p.id==x.zoneId))	
	    	}
	    	
	    	if ( emptyBreaks == 1 )
	    	{
	    		return d;
	    	}
	    	if ( emptyBreaks == 0 )
	    	{
	    		return d.filter(p => !this.compareData.spotsInGrid[p.id]);
	    	}
	    	return d.filter(p => this.compareData.spotsInGrid[p.id]);

	    },
    dropFile($event, line, rowIdx, lineREF)
    {
      let that = this;
      event.preventDefault();
      event.stopPropagation();
     
      this.showDropzone = false;
      const files = event.dataTransfer.files;
      const itemArray = [...files];
      
      const item = itemArray.find((i) => true);
      this.$refs.confirm.confirm( {text: 'Load Booking-file: ' + item.name, title: "Upload Bookings", button1: "Cancel", button2: "Ok"}).then(x => {
    	  this.startLoader();
    	  let check =  new Promise(function (resolve, reject) {
    		  const formData = new FormData();
			  that.leaveLine(lineREF);
		      formData.append('file', item);      
		      if (item) that.upload(formData, line, rowIdx, item.name);
		      that.stopLoader();

		   });
      });
    },
    fmtTimePart( aTime) {
	   	if ( aTime < 10 ) 
	   	{
	   		return "0"+aTime;
	   	}
	   	return ""+aTime;
	},
    printTime(time)
    {
      //console.log( "scanTime("+val+") " + this.time )
      //alert("scanTime("+JSON.stringify(val)+") " + this.time)
      if ( isNaN( time ) || ! time  )
      {
      	time = 0;
      }
    	let ss = time % 60;
      let mm = ((time - ss) / 60 ) % 60;
      let hh = ((time - ss - mm * 60) / 3600 ) % 60;
      let hhVal = this.fmtTimePart(hh);
      let mmVal = this.fmtTimePart(mm);
      let ssVal = this.fmtTimePart(ss);
      return mmVal+":"+ssVal;
     },
    startDragGrid( evt, source, what ) {
   		evt.dataTransfer.dropEffect = what
        evt.dataTransfer.effectAllowed = what
        evt.dataTransfer.setData('dndType', what)
        evt.dataTransfer.setData('sourceId', source.id)
   	},
   	onDropGrid (evt, target, moveType) 
    {
       const sourceId = evt.dataTransfer.getData('sourceId');
       let source = this.compareData.details.find(p=>p.id==sourceId);
       if ( !source )
       {
    	   source = this.compareData.activeDetails.find(p=>p.id==sourceId);
       }
       const dndType = evt.dataTransfer.getData('dndType');
       if ( ! moveType )
       {
       	  moveType = dndType;
       }
       this.move( source, target)
	   return;
	},
    async upload(data, line, rowIdx, name) {
      let uploadFile = await this.uploadFile(data, line, rowIdx, name);
    },
    
    
    showBreak( data)
    {
		
	},
    
    
	importBookings( data)
    {
		this.$refs.confirm.confirm( {text: "Import "+this.data.nSpots+" bookings", title: "Import "+this.data.fileName, button1: "Cancel", button2: "Ok"}).then(x => {
		this.pbData = data;
        this.pbAction = "importBookingsInternal";
        this.pbTitle = "Import bookings";
 	    this.showProgressBar = true;
 	    });
    },
    importBookingsInternal( myId, api)
    {
		let that = this;
	  	api.put( bookingImportAPI+"/loadImportedFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myId, this.data   )
	          .then( response => 
	          { 
	  			that.data = response.data;
	  			that.dataStored = that.data;
	  			that.date = new Date(that.data.date);
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}
	            that.showProgressBar = false;
	            that.$toast.success("File '"+name+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.showProgressBar = false;})
	},
	
	syncMaterial( data)
    {
		this.$refs.confirm.confirm( {text: "Synchronize", title: "Sync materials", button1: "Cancel", button2: "Start"}).then(x => {
		this.pbData = data;
        this.pbAction = "syncMaterialInternal";
        this.pbTitle = "Sync materials";
 	    this.showProgressBar = true;
 	    });
    },
    syncMaterialInternal( myId, api)
    {
		let that = this;
	  	api.post( bookingImportAPI+"/syncMaterials/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myId, this.data   )
	          .then( response => 
	          { 
	  			that.data = response.data;
	  			that.dataStored = that.data;
	  			that.date = new Date(that.data.date);
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}
	            that.showProgressBar = false;
	            that.$toast.success(that.data.message, 'Ok', { timeout: 3000, position: "topRight" });
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.showProgressBar = false;})
	},
    
    updateCompare()
    {
    	this.compareData = null;
    },
    reload()
    {
    	this.compare(this.selectedHeader);
    },
    
    uploadFile(formData, line, rowIdx, name) 
    {
      let that=this;
      

      HTTPMP.put( bookingImportAPI+"/scanFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId, formData)
        .then( response => 
        { 
			that.data = response.data;

			that.dataStored = that.data;
			that.date = new Date(that.data.date);
			if ( that.data.media )
			{
				that.stationId = that.data.media.id;
			}
            that.stopLoader();
            that.$toast.success("File '"+name+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.stopLoader();})

    },
    action( what, myId, api)
    {
    	console.log(what+"/"+ myId+"/"+api)
   		this[what](myId, api)
    },
    importGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importGridInternal";
        this.pbTitle = "Import grid";
 	    this.showProgressBar = true;
    },
	importJustGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importJustGridInternal";
        this.pbTitle = "Import grid";
 	    this.showProgressBar = true;
    },
    importBreakGrid( headerId, data)
    {
		this.pbHeaderId = headerId;
    	this.pbData = data;
        this.pbAction = "importBreakGridInternal";
        this.pbTitle = "Import break grid";
 	    this.showProgressBar = true;

    },
    importDayGridXML( headerId, data)
    {
    	this.pbHeaderId = headerId;
    	this.pbData = data;
        this.pbAction = "importDayGridXMLInternal";
        this.pbTitle = "Import daily break grid";
 	    this.showProgressBar = true;

    },
	linkBreaks( headerId, data)
	  {
	    	this.pbHeaderId = headerId;
	    	this.pbData = data;
	        this.pbAction = "linkBreaksInternal";
	        this.pbTitle = "Link grid breaks to breaks";
	 	    this.showProgressBar = true;

	 },
    
    importGridCreateHeader( headerId)
    {
    	this.pbHeaderId = 0;
        this.pbAction = "importGridCreateInternal";
        this.pbTitle = "Import grid (with new header)";
 	    this.showProgressBar = true;
    },
    importDayGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importDayGridInternal";
        this.pbTitle = "Import as day grid";
 	    this.showProgressBar = true;
    },
    importDayGridInternal(myId, api)
    {
    	let that = this;
    	console.log("importDayGridInternal")
    	api.put( gridAPI+"/importDayGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId)
        .then( response => 
        {             
            that.$toast.success("day grid '"+name+"' imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
			that.gridImported = response.data;
			if ( that.gridImported && that.gridImported.from)
			{
				that.dateFrom = that.gridImported.from;
				that.dateTo = that.gridImported.to;
			}
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },	
	linkBreaksInternal(myId, api)
    {
    	let that = this;
    	console.log("importDayGridInternal")
    	api.put( gridAPI+"/linkBreaksToDayBreaks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myId, that.gridImported)
        .then( response => 
        {             
            that.$toast.success("day grid linked'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },	
    importBreakGridInternal(myId, api)
    {
        
    	let that = this;
    	this.showBreak( this.pbData.programLines);
    	api.put( gridAPI+"/importBreakGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId, {programLines: this.pbData.programLines})
        .then( response => 
        {             
            that.$toast.success("break grid imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },
    importDayGridXMLInternal(myId, api)
    {
        
    	let that = this;
    	console.log("importBreakGridInternal")
    	api.put( gridAPI+"/importDayGridXML/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId, {programLines: this.pbData.programLines})
        .then( response => 
        {             
            that.$toast.success("break grid imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },
    importGridInternal(myId, api)
    {
    	let that = this;
    	
    	api.put( gridAPI+"/importGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.fileId+"/"+this.pbHeaderId+"/"+myId)
        .then( response => 
        {             
            that.$toast.success("Grid imported'", 'Ok', { timeout: 1000, position: "topRight" });
            that.showProgressBar = false;
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
    },
    hoverLine(line)
    {
    	$('#'+line).addClass('reqLineBG2');
    },
    hoverLineMain(line)
    {
    	//$('#'+line).addClass('reqLineBG');
    },
    leaveLine(line)
    {
    	$('#'+line).removeClass('reqLineBG2');
    },
	runReport( report) 
	{
		this.pbAction = "runReportInternal";
    	this.pbTitle = "Run "+ report.info;
    	this.pbReport=report;
  	    this.showProgressBar = true;
	},
	// record SpecialReportRequest ( String from, String to, Long mediaId, String classname) {} 
    runReportInternal(  myId, api) {
        let that = this;
		let from = momentTZ(this.dateFrom).utcOffset(0, true).format().split('T')[0];
		let to = momentTZ(this.dateTo).utcOffset(0, true).format().split('T')[0];
        let req = { from: from, to: to, mediaId: this.stationId, classname: this.pbReport.classname};
		//alert( JSON.stringify( req));
    	let dataLoad = new Promise(function (resolve, reject) {
            api.post( reportAPI+"/runSpecialReport/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                 	
                    that.reportData = response.data;
					//alert( JSON.stringify( that.reportData))
					that.showProgressBar = false;
                    resolve (true);                    
                 }).catch(e => {
					    that.showProgressBar = false;
                        that.$toast.error("loading data for report: " + e.response.data, 'Error', { position: "topRight" });
                        reject();
                    });
	      });
    },
	downloadUnzip( data)
    {
		
    	this.$refs.pdfView.download(data.data, data.name);
    },
    loadStation( stationId, activeDate )
    {
    	let that = this;
    	let userLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                	 that.user = response.data;
                	 that.stationIds = that.user.media;
                	 
                	 resolve (that.stationIds);
                 }).catch(e => {
                     that.$toast.error("loading user (ImportGrid): " + e.response.data, 'Error', { position: "topRight" });
                     that.stopLoader();
                     reject ();
                 });
	      });
    	userLoad.then( stationIds => {
        	let stationLoad = new Promise(function (resolve, reject) {
        	    //alert(sessionStorage.unitId);
        	    
                console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0");
				HTTP.post( reportAPI+"/getSpecialReports/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	        		 .then( response => 
	                 {
	                 	var allStations = response.data.stations;
						allStations.sort((a,b)=>a.name.localeCompare(b.name));
						that.stations = allStations.map(a => {return {id: a.id, label: a.name, visible: true}});
	                    that.reports = response.data.reports;
						console.log( JSON.stringify( that.reports))
	                    that.selectedStation =  that.stations[0];
	                    resolve (that.stations);                    
	                 }).catch(e => {
	                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
	                        reject ();
	                    });
    	      });
        	
        });
    },
    headerRClicked(event, header, detail)
    {
    	//alert( JSON.stringify({header: header, detail: detail}));
        event.preventDefault();
        this.$refs.menu.open(event, {header: header, detail: detail} )
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	storeSettings() 
	{
		try
		{
	    	let obj = this.$data;
	    	//this.userUpdated();
	    	store( obj);
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	},
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  beforeDestroy() {
    this.storeSettings();
  },
  created() {
	if ( sessionStorage.lastMediaId )
	{
		this.stationId = sessionStorage.lastMediaId;
	}
	
	let restored = restore();
	if ( restored && restore.data )
	{
		this.data = restored.data;
		this.dataStored = restored.dataStored;
		this.gridImported = restored.gridImported;
	}

	this.dateFrom = new Date().toISOString().split('T')[0]
	this.dateTo = new Date().toISOString().split('T')[0]
	this.loadStation();

	initAppMode();
  },
  watch: {
   stationId: function() {
	   sessionStorage.lastMediaId = this.stationId;
   },
   selectedStates: function() {
	    
		
	},
  },
  updated() {
  		//console.log("update...");

 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}
.todrag {
 display: block;
 width: 100%; 
 height: 16pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  width: 100%;
  display: block;
}
.progTitle {
  white-space: normal;
  width: 100%;
  display: flex;
  border: 1pt solid grey;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
  
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 72%;  
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.reqLineBG2 {
    cursor: pointer;
    color: #fff;
	background-color: #888 !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
.header {
  font-size: 14pt;
  font-weight: bold;
}
.spotTdS {
  font-size: 9pt;
  max-width: 4ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.spotTdM {
  font-size: 9pt;
  max-width: 10ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.spotTdL {
  font-size: 9pt;
  max-width: 30ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.breakLine:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
.right {
	text-align: right;
}
</style> 